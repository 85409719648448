import React from 'react';
import './App.css';
import Auth from './meisters/Auth';

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Auth />
      </div>
    );
  }
}

export default App;
